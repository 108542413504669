<template>
<div id="app">    
        <CCard>
          <CCardHeader>
            <h4>Account Summary</h4>
            <!--div class="card-header-actions">
              <a 
                href="https://coreui.io/vue/docs/components/tabs" 
                class="card-header-action" 
                rel="noreferrer noopener" 
                target="_blank"
              >
                <small class="text-muted">docs</small>
              </a>
            </div-->
          </CCardHeader>
          <CCardBody>
            <CTabs>
               <CTab title="Internal" active> <br/> 
                             
                  <internal/>       
               </CTab>
               <CTab title="External"><br/>
                
                  <external/>
               </CTab>
              
            </CTabs>
          </CCardBody>
        </CCard>      

</div>
</template>

<script>
import AuthService from '@/api/AuthService.js';
import InternalAccount from './internal';
import ExternalAccount from './external';

export default {
    name: 'accounts',
    components: {
      'internal': InternalAccount,
      'external': ExternalAccount      
    },  
    data (){     
    },    
    created (){ 
    },   
    methods: {        
    },
 };

</script>