<template>
  <CRow>
    <CCol col="12" xl="12">
              
        <CCardBody>
          <CRow>
              <CCol md="4">                   
                   <CInput
                      label="From Date"
                      type="date"
                      horizontal                      
                      v-model="fromDate" 
                      @input="startDateOnChange()" 
                    />            
              </CCol>
              <CCol md="4">                   
                   <CInput
                      label="To Date"
                      type="date"
                      horizontal
                      v-model="toDate" 
                      @input="toDateOnChange()" 
                    />            
              </CCol> 
              <CCol md="4">                   
                   <CInput
                      label="Search"                     
                      horizontal
                      v-model="filter" 
                      placeholder="Type to Search" 
                    />            
              </CCol>                  
          </CRow>

          <div class="table-responsive table">
          <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">

          <template v-slot:cell(createDate)="{ item }"> 
              {{ item.createDate | dateWithTime }}
          </template>         

        </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>
        
        </CCardBody>      
    </CCol>
  </CRow>
</template>


<script>
import AuthService from '@/api/AuthService.js';
import AdminService from '@/api/AdminService.js';
import AccountService from '@/api/AccountService.js';
import moment from 'moment';

 export default {
    name: "users",
    data() { var date = new Date();
      return { 
        fromDate: new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substr(0, 10),
        toDate: new Date().toISOString().substr(0, 10),         
        items:[],             
        fields: [   
          {
            name : 'summeryDate',
            key: 'summeryDate',
            label : 'Summary Date',
            sortable: true
          },  
          {
            key: 'accountRef',
            label : 'Account Ref',
            sortable: true
          }, 
          {
            key: 'accountName',
            label : 'Account Name',
            sortable: true
          },
          {
            key: 'openingBalance',
            label : 'Opening Balance',
            sortable: true
          },
          {
            key: 'closingBalance',
            label : 'Closing Balance',
            sortable: true
          },
          {
            key: 'incomingAmountTotal',
            label : 'Total Incoming',
            sortable: true
          },
          {
            key: 'outgoingAmountTotal',
            label : 'Total Outgoing',
            sortable: true
          } 
          
          ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
        passpar:'',
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },

        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        }
      },

    created () {     
      this.getRequestParams();
      this.getExternalAccounts(this.passpar); 
    },
    methods: {  

      isSuperAdmin(){
        if(localStorage.getItem('sa')==="false"){  
            this.$router.push('/dashboard');
        }
      },

      getRequestParams(){
          var tempst = this.fromDate.split("-");
          var start  = tempst[2]+"-"+tempst[1]+"-"+tempst[0];
          var temped = this.toDate.split("-");
          var end    = temped[2]+"-"+temped[1]+"-"+temped[0];
          let params = {};
          params['start'] = start;
          params['end']   = end;     
          params['searchString'] = "";
          this.passpar = params;
      },

      startDateOnChange(){
        this.getRequestParams();
        this.getExternalAccounts(this.passpar); 
      },

      toDateOnChange(){
        this.getRequestParams();
        this.getExternalAccounts(this.passpar); 
      },    

      getExternalAccounts: function(params) {
        AccountService.getExternalAccounts(params).then(resp => { //console.log(resp);
           this.items = resp;
        }, error => {
          this.loading = false;
        });
      }, 
      
      getUserRoleList: function() {
        AdminService.getUserRoleList().then(resp => { //console.log(resp);
           this.items = resp.payload;          
        }, error => {
          this.loading = false;
        });
      }


    }    
 }; 

</script>
<style>
    .table{ overflow-y:scroll; }
</style>